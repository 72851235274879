import { documentReady } from "./utils/dom"
import getCookie from "./utils/getCookie"

documentReady(() => {
  const containerElement = document.getElementById("budget-banner")

  if (!containerElement) {
    return
  }

  const hideBanner = getCookie("hideBudgetBanner") === "true"

  if (hideBanner) {
    containerElement.classList.add("hide")
    return
  }

  const closeButton = containerElement.querySelector(".close")

  if (!closeButton) {
    return
  }

  const in60Days = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)

  closeButton.addEventListener("click", () => {
    document.cookie = `hideBudgetBanner=true; expires=${in60Days.toUTCString()}`
    containerElement.classList.add("hide")
  })
})
