import $ from "jquery"
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip"
import "bootstrap-sass/assets/javascripts/bootstrap/popover"

import { documentReady } from "./utils/dom"

documentReady(() => {
  /**
   * Adds a bootstrap tooltip element
   * on elements with
   * 'data-toggle="tooltip"'
   */
  $("html").find("[data-toggle=tooltip]").tooltip()
})
