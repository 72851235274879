import "bootstrap-sass/assets/javascripts/bootstrap/transition"
import "bootstrap-sass/assets/javascripts/bootstrap/collapse"
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown"
import "bootstrap-sass/assets/javascripts/bootstrap/button"
import "bootstrap-sass/assets/javascripts/bootstrap/modal"
import "bootstrap-sass/assets/javascripts/bootstrap/tab"
import "bootstrap-sass/assets/javascripts/bootstrap/alert"
import "bxslider/dist/jquery.bxslider"
import "bxslider/dist/jquery.bxslider.css"

// Should be merged with styles/ in the future
import "./styles-2018/style.scss"
import "./styles/main.scss"

// Required by Dashboard JS on product detail view to set the height of the product preview iframe
// See also static/js/dashboard/product-update.main.js
import "iframe-resizer/js/iframeResizer.contentWindow"

import "./map"
import "./password"
import "./video"
import "./selectpicker"
import "./tooltips"
import "./datepicker"
import "./budget-banner"

import "./pages/content"

// TODO Splitup further
import "./main"

import "./mobileMenu"

window.onload = () => {
  document.querySelector("body").classList.add("page-has-loaded")
}

import "@adyen/adyen-web/dist/adyen.css"
