import { hasClass } from "./dom"

function findClosest(element, fn) {
  if (!element) {
    return
  }
  return fn(element) ? element : findClosest(element.parentElement, fn)
}

export default function detectOutsideClick(targetClass, onOutsideClick) {
  let dragging = false

  function handleClick(event) {
    if (dragging) {
      return
    }

    const target = findClosest(event.target, (el) => hasClass(el, targetClass))

    if (!target) {
      onOutsideClick()
    }
  }

  document.addEventListener("click", handleClick, false)
  document.addEventListener("touchend", handleClick, false)
  document.addEventListener("touchmove", () => {
    dragging = true
  })
  document.addEventListener("touchstart", () => {
    dragging = false
  })
}
