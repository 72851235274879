import { documentReady, isHidden } from "./utils/dom"
import detectOutsideClick from "./utils/detect-outside-click"

documentReady(() => {
  const btn = document.querySelector(".hamburger-menu")
  const nav = document.querySelector(".main-header-mobile-nav")
  const close = document.querySelector(".main-header-mobile-nav-close")
  const body = document.querySelector("body")

  if (!btn || !nav || !close) {
    return
  }

  function setActive(active, e) {
    e && e.preventDefault()
    e && e.stopPropagation()

    const action = active ? "add" : "remove"

    btn.classList[action]("active")
    nav.classList[action]("active")
    body.classList[action]("overlayed")
  }

  btn.addEventListener("click", (e) => setActive(true, e))
  close.addEventListener("click", (e) => setActive(false, e))

  window.addEventListener("resize", () => isHidden(btn) && setActive(false))

  detectOutsideClick("main-header-mobile-nav", () => setActive(false))
})
