import $ from "jquery"
import "bootstrap-datepicker"
import { documentReady } from "./utils/dom"

/**
 * Datepicker integration
 * German language setting
 */
$.fn.datepicker.dates.de = {
  days: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ],
  daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam", "Son"],
  daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  today: "Heute",
  clear: "Leeren",
}

function makeDate(s) {
  if (!s) {
    return ""
  }
  const dateParts = s.split("-")
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1]) - 1
  const day = parseInt(dateParts[2])
  return new Date(year, month, day)
}

function initDatepicker() {
  const _$datepickerDesktop = $("#mf-js_datepicker")
  const _$datepickerMobile = $("#mf-js_datepicker-mobile")

  const datepickerOptions = {
    startDate: makeDate(_$datepickerDesktop.attr("data-mf-deadline")),
    endDate: makeDate(_$datepickerDesktop.attr("data-mf-end-date")),
    beforeShowDay: (date) => {
      const sDate =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2)
      const isCourseDate = "mf-c_product-detail-is-course-date"
      const isDeadline = "mf-c_product-detail-is-deadline"
      const isStartDate = "mf-c_product-detail-is-start-date"
      const isEndDate = "mf-c_product-detail-is-end-date"

      const classes = []
      const weekdays = _$datepickerDesktop.attr("data-mf-weekdays")
      const sFixedDates = _$datepickerDesktop.attr("data-mf-fixed-dates").split(" ")
      const sDeadLine = _$datepickerDesktop.attr("data-mf-deadline")
      const sStartDate = _$datepickerDesktop.attr("data-mf-start-date")
      const sEndDate = _$datepickerDesktop.attr("data-mf-end-date")

      // Python's date.weekday() yields 0 - Monday, 6 - Sunday, but JavaScript's
      // date.getDay() yields 0 - Sunday, 6 - Saturday, so we need to adjust by -1,
      // which is equivalent to +6 in mod 7.
      if (weekdays.indexOf((date.getDay() + 6) % 7) !== -1) {
        const endDate = makeDate(sEndDate)
        const startDate = makeDate(sStartDate)
        if ((!startDate || date >= startDate) && (!endDate || date <= endDate)) {
          classes.push(isCourseDate)
        }
      } else if ($.inArray(sDate, sFixedDates) !== -1) {
        classes.push(isCourseDate)
      }
      if (sStartDate === sDate) {
        classes.push(isStartDate)
      }
      if (sEndDate === sDate) {
        classes.push(isEndDate)
      }
      if (sDeadLine === sDate) {
        classes.push(isDeadline)
      }
      return {
        enabled: false,
        classes: classes.join(" "),
      }
    },
  }
  /**
   * Datepicker had to be integrated twice,
   * because of the different mobile sorting
   * and IE8 layout
   *
   * Datepicker desktop
   */
  _$datepickerDesktop.datepicker(datepickerOptions)
  /* Datepicker mobile */
  _$datepickerMobile.datepicker(datepickerOptions)
}

documentReady(() => {
  initDatepicker()
})
