import { documentReady } from "../utils/dom"

documentReady(() => {
  if (typeof window.print !== "function") {
    return
  }

  const printButtons = document.querySelectorAll(".print-page")

  printButtons.forEach((printButton) => {
    printButton.style.visibility = "visible"
    printButton.addEventListener("click", () => window.print())
  })
})
