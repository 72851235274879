import $ from "jquery"
import "bootstrap-select"
import "bootstrap-select/dist/css/bootstrap-select.css"
import "bootstrap-select/dist/js/i18n/defaults-de_DE"
import { documentReady } from "./utils/dom"
import { isTouchDevice } from "./utils/feature-detection"

documentReady(() => {
  const $mobileSelects = $("#mf-js_filter-mobile select")
  // ios automatically selects the first element and this triggers a change event
  // http://stackoverflow.com/questions/34660500/mobile-safari-multi-select-bug
  if (navigator.platform.match(/(iPhone|iPad|iPod)/i)) {
    $mobileSelects.filter("#id_schedule_types").prepend($("<option>", { disabled: "disabled" }))
  }

  $mobileSelects.selectpicker().parent().find(".btn-group").removeClass("btn-group dropup")
  if (isTouchDevice()) {
    $mobileSelects.selectpicker("mobile")
  }

  // Use bootstrap-select for birthday select fields
  $("#id_birthday_day").selectpicker({ width: "fit", dropupAuto: false })
  $("#id_birthday_month").selectpicker({ width: "fit", dropupAuto: false })
  $("#id_birthday_year").selectpicker({ width: "fit", dropupAuto: false })

  const appointmentChooser = $("#js-appointment-chooser")
  appointmentChooser.selectpicker({
    width: "auto",
    style: "appointment-chooser",
    dropupAuto: false,
  })

  appointmentChooser.on("changed.bs.select", (e) => {
    window.location.href = $(e.currentTarget).val()
  })
})
