import fitvids from "fitvids"
import { documentReady } from "./utils/dom"

documentReady(() => {
  // Responsively resize YouTube/Vimeo videos
  // embedded into product descriptions.
  fitvids({
    players: ["iframe.ql-video"],
  })
})
