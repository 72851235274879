import $ from "jquery"
import getCookie from "./utils/getCookie"

$(document).ready(function () {
  "use strict"

  /* Prevent forms from double submission */
  $(".js-loading-text").on("click", function () {
    var form = $(this).parents("form")
    if (!form || $(":invalid", form).length === 0) {
      $(this).button("loading")
    }
  })

  /**
   * Toggle FAQ answers when user clicks on question
   */

  var _$faqPanel = $("#mf-js_faq-container .panel")

  _$faqPanel.on("show.bs.collapse", function () {
    $(this).addClass("mf-c_faq-opened")
  })
  _$faqPanel.on("hide.bs.collapse", function () {
    $(this).removeClass("mf-c_faq-opened")
  })

  /**
   *  Jump to google map via anchor tag
   *  and opens the correct tab
   */

  var _$jumpToMapButton = $("#mf-button_jump-to-map")

  _$jumpToMapButton.on("click", function (event) {
    event.preventDefault()
    $("html, body").animate(
      {
        scrollTop: $("#mf-js_map").offset().top,
      },
      1000,
    )
  })

  /**
   * Scrollbar margin problem in Windows
   */
  function osDetection() {
    if (navigator.appVersion.indexOf("Win") !== -1) {
      $(".mf-c_product-thumbnail").css({
        "margin-right": "-30px",
      })
      $(".mf-header-wrapper").css({
        "margin-right": "-30px",
      })
    }
  }

  osDetection()

  $(window).resize(function () {
    if ($(window).width() < 768) {
      $("[data-toggle=tooltip]").tooltip("disable")
    }
  })

  $(document).on("changed.bs.select", ".filters select", function () {
    $(this).parents("form").submit()
  })

  /**
   * Show Notifications
   */

  $(".notification").first().show()

  $(".notification-bottom a").on("click", function () {
    var $notification = $(this).parents(".notification")
    var pk = $notification.data("pk")
    var csrftoken = getCookie("csrftoken")

    $.post("/notification/", { notification_pk: pk, csrfmiddlewaretoken: csrftoken }).done(
      function () {
        $notification.remove()
        if ($(".notification").length === 0) {
          $(".notification-bg").fadeOut("slow")
        } else {
          $(".notification").first().show()
        }
      },
    )
  })

  $(".js-product-slider").each(function (i, e) {
    var $e

    $e = $(e)
    $e.bxSlider({
      slideWidth: 385,
      maxSlides: $e.data("max-slides"),
      slideMargin: 0,
      moveSlides: 1,
      pager: false,
      controls: $e.data("controls"),
      onSliderLoad: function () {
        $(".area__slider").css({ visibility: "visible" })
      },
    })
  })
})
