import L from "leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-sleep"
import { documentReady } from "./utils/dom"
import { isTouchDevice } from "./utils/feature-detection"
import iconRetina from "leaflet/dist/images/marker-icon-2x.png"
import icon from "leaflet/dist/images/marker-icon.png"
import shadow from "leaflet/dist/images/marker-shadow.png"

// Fix an issue causing the map marker icon to not be shown when using
// webpack
// https://machtfit.atlassian.net/browse/MAC-2692
// https://github.com/Leaflet/Leaflet/issues/4968
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: icon,
  shadowUrl: shadow,
})

function initMap(mapContainer) {
  let map
  const lat = parseFloat(mapContainer.dataset.latitude)
  const lon = parseFloat(mapContainer.dataset.longitude)
  const productTitle = mapContainer.dataset.title
  const productPartner = mapContainer.dataset.partner
  const productAddress = mapContainer.dataset.address
  let locationControl

  const OpenExternallyControl = L.Control.extend({
    options: {
      position: "topright",
    },
    // extend() apparantely does not work with arrow functions
    // eslint-disable-next-line func-names
    onAdd: function () {
      const container = L.DomUtil.create(
        "div",
        "leaflet-bar leaflet-control leaflet-control-custom",
      )
      const a = L.DomUtil.create("a", "glyphicon glyphicon-new-window")
      const size = "1.8em"
      const label = productPartner + " - " + productTitle
      if (navigator.platform.match(/(iPhone|iPad|iPod|Mac)/i)) {
        a.href = "http://maps.apple.com/?ll=" + lat + "," + lon + "&q=" + label
      } else {
        a.href = "geo:" + lat + "," + lon + "?q=" + lat + "," + lon + "(" + label + ")"
      }
      a.title = "In Karten-App des Systems öffnen"
      a.style.backgroundColor = "white"
      a.style.width = size
      a.style.height = size
      a.style.fontSize = size
      a.style.lineHeight = size
      a.style.textAlign = "center"
      a.style.verticalAlign = "middle"

      container.appendChild(a)

      return container
    },
  })

  const OwnLocationControl = L.Control.extend({
    options: {
      position: "bottomleft",
    },
    // extend() apparently does not work with arrow functions
    // eslint-disable-next-line func-names
    onAdd: function () {
      this.container = L.DomUtil.create(
        "div",
        "leaflet-bar leaflet-control leaflet-control-custom leaflet pace pace-inactive glyphicon glyphicon-screenshot",
      )
      const paceActivity = L.DomUtil.create("div", "pace-activity-inline")
      this.container.appendChild(paceActivity)
      const size = "1.8em"
      this.container.style.backgroundColor = "white"
      this.container.style.width = size
      this.container.style.height = size
      this.container.style.fontSize = size
      this.container.style.lineHeight = size
      this.container.style.textAlign = "center"
      this.container.style.verticalAlign = "middle"

      this.container.onclick = () => {
        locationControl.setLoading(true)
        map.locate({ setView: false, maxZoom: 16 })
      }

      return this.container
    },
    // extend() apparently does not work with arrow functions
    // eslint-disable-next-line func-names
    setLoading: function (loading) {
      if (loading) {
        L.DomUtil.removeClass(this.container, "pace-inactive")
        L.DomUtil.removeClass(this.container, "glyphicon")
        L.DomUtil.removeClass(this.container, "glyphicon-screenshot")
      } else {
        L.DomUtil.addClass(this.container, "pace-inactive")
        L.DomUtil.addClass(this.container, "glyphicon")
        L.DomUtil.addClass(this.container, "glyphicon-screenshot")
      }
    },
  })

  const SleepButtonControl = L.Control.extend({
    // extend() apparently does not work with arrow functions
    // eslint-disable-next-line func-names
    initialize: function (opts) {
      L.setOptions(this, opts)
    },

    options: {
      position: "topright",
      prompt: "Karte deaktivieren",
      styles: {
        backgroundColor: "white",
        padding: "5px",
        border: "2px solid gray",
      },
    },

    // extend() apparently does not work with arrow functions
    // eslint-disable-next-line func-names
    buildContainer: function () {
      const self = this
      const container = L.DomUtil.create("p", "sleep-button")
      const boundEvent = this._nonBoundEvent.bind(this)
      container.appendChild(document.createTextNode(this.options.prompt))
      L.DomEvent.addListener(container, "click", boundEvent)
      L.DomEvent.addListener(container, "touchstart", boundEvent)

      Object.keys(this.options.styles).map((key) => {
        container.style[key] = self.options.styles[key]
      })

      return (this._container = container)
    },

    // extend() apparently does not work with arrow functions
    // eslint-disable-next-line func-names
    onAdd: function () {
      return this._container || this.buildContainer()
    },

    // extend() apparently does not work with arrow functions
    // eslint-disable-next-line func-names
    _nonBoundEvent: function (e) {
      L.DomEvent.stop(e)
      if (this.map) {
        this.map.sleep._sleepMap()
      }
      return false
    },
  })

  const mapOpts = {
    sleep: true,
    hoverToWake: false,
    wakeMessage: "Zum Aktivieren klicken",
    wakeMessageTouch: "Zum Aktivieren antippen",
    sleepButton: () => {
      return new SleepButtonControl()
    },
    attributionControl: false,
  }

  if (!isTouchDevice()) {
    mapOpts.sleepNoteStyle = {
      display: "none",
    }
    mapOpts.sleepOpacity = 1
  }

  map = L.map(mapContainer, mapOpts).setView([lat, lon], 14)

  const attributionControl = L.control.attribution({ prefix: false })
  const currentYear = new Date().getFullYear()

  attributionControl.addAttribution("1987-" + currentYear + " &copy; HERE, Deutschland")
  map.addControl(attributionControl)

  L.tileLayer("https://osm-proxy.machtfit.de/hot/{z}/{x}/{y}.png", {
    maxZoom: 18,
  }).addTo(map)

  L.marker([lat, lon])
    .addTo(map)
    .bindPopup(
      "<b>" +
        productTitle +
        "</b><br /><br /><strong>" +
        productPartner +
        "</strong><br />" +
        productAddress,
    )
    .openPopup()

  map.on("locationfound", (e) => {
    locationControl.setLoading(false)
    const radius = e.accuracy / 2
    L.circle(e.latlng, radius).addTo(map)

    map.fitBounds([[lat, lon], e.latlng])
  })

  map.on("locationerror", (e) => {
    locationControl.setLoading(false)
    window.alert("Position konnte nicht ermittelt werden: " + e.message)
  })

  locationControl = new OwnLocationControl()

  map.addControl(new OpenExternallyControl())
  map.addControl(locationControl)
}

documentReady(() => {
  const mapContainer = document.getElementById("mf-js_map")

  if (mapContainer) {
    initMap(mapContainer)
  }
})
